@import "variables";

body {
    background-color: $white-color !important;
}

.text-right {
    text-align: right;
}

.align-center {
    align-items: center;
}

.mb-1 {
    margin-bottom: 10px;
}

.MuiSwitch-colorPrimary.Mui-checked {
    color: $black-color !important;
}

.MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
    background-color: $black-color !important;
}

.MuiSwitch-thumb {
    box-shadow: none !important;
}

span.back-text {
    font-weight: bold;
    text-transform: capitalize;
    margin-left: 3px;
    font-size: 16px;
}

.MuiChip-colorPrimary {
    background-color: $green-color !important;
}

// NO Data found
.no-data {
    img {
        max-width: 250px;
    }

    h5 {
        font-size: 22px;
    }
}

.delivery-partner {
    .no-data {
        img {
            max-width: 200px;
        }
    }
}

// Navigation
ul.MuiList-root.main-nav.MuiList-padding {
    padding-top: 20px !important;

    .menu-items-list-view {
        border-bottom: 1px solid #eee !important;
        border-left: 0px solid $white-color !important;
        height: auto;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 22px;

        .MuiListItemIcon-root {
            min-width: auto;
            margin-right: 26px;
        }

        &:hover {
            background-color: $black-color !important;
            color: $white-color !important;
            border-left: 0px solid $black-color !important;
            border-color: $black-color !important;

            svg {
                color: $white-color;
            }

            span {
                color: $white-color;
            }
        }
    }

    .active-drawer {
        background-color: $black-color !important;
        color: $white-color !important;
        border-left: 0px solid $black-color !important;
        border-color: $black-color !important;

        svg {
            color: $white-color;
            // color: $black-color !important;
        }

        span {
            color: $white-color;
        }
    }
}

// Dashboard
.dashboard-container {
    height: 100% !important;
}

.main-loader {
    height: 100vh !important;

    .Loader__background {
        z-index: 9999 !important;
    }

    .Loader__content {
        height: 100vh;
    }
}

.login-background-contianer {
    background-color: $white-color;

    .MuiPaper-root {
        -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        border-radius: 0;
    }

    &::before {
        content: "";
        // background: url("../assets/images/top-flower.png") no-repeat center/contain;
        display: inline-block;
        max-width: 350px;
        width: 100%;
        height: 450px;
        position: absolute;
        top: 0;
        right: 20px;
        z-index: 0;
    }

    &:after {
        content: "";
        // background: url("../assets/images/bottom-flower.png") no-repeat center/contain;
        display: inline-block;
        max-width: 350px;
        width: 100%;
        height: 450px;
        position: absolute;
        bottom: 0;
        left: 20px;
        z-index: 0;
    }

    .login-container {
        .login-container-item {
            height: auto !important;
            margin: auto;
            z-index: 1;
            line-height: 0;

            .full-height {
                height: 100%;
            }

            .MuiFormControl-root {
                margin-bottom: 15px;
            }

            button {
                margin: 0;
            }
        }

        .login-logo-div {
            width: 100%;
            margin-bottom: 30px;

            img {
                width: 100%;
                max-width: 300px;
                max-height: 100px;
                object-fit: contain;
            }
        }

        .form-container {

            // margin-top: 70px;
            .login-button {
                &:disabled {
                    background-color: #6877ca !important;
                }
            }
        }

        .form-container-item {
            margin: auto;
        }
    }
}

.add-button {
    button {
        height: 56px !important;
    }
}

//Product Common Card Preview
.cus-pro-list {
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 0;
    border: 0px solid #d3d3d3;
    border-radius: 10px;
    background-color: $white-color;
    z-index: 1;
    padding: 30px 20px;
    box-shadow: none;
    cursor: pointer;
    overflow: hidden;

    .flower-type {
        color: $white-color;
        top: 0;
        position: absolute;
        left: 7px;
        right: auto;
        font-weight: 500;
        padding: 15px 2px 0;
        font-size: 16px;
        z-index: 2;

        &:before {
            content: "";
            background-color: #46952f;
            display: inline-block;
            width: 167px;
            height: 130px;
            -webkit-transform: rotate(-32deg);
            -moz-transform: rotate(-32deg);
            transform: rotate(-32deg);
            position: absolute;
            top: -78px;
            left: -63px;
            z-index: -1;
        }
    }

    .pro-add-to-cart-qty {
        position: absolute;
        top: 15px;
        right: 15px;
        border: 2px solid $black-color;
        border-radius: 50%;
        padding: 8px;
        line-height: 0;
        width: 35px;
        height: 35px;

        img {
            width: 15px;
            height: auto;
        }

        .hover {
            display: none;
        }

        &:hover {
            background-color: $light-blue;

            .nohover,
            img {
                display: none;
            }

            .hover {
                display: block;
            }

            &:before {
                content: "";
                // background: url("/images/Icon-ionic-ios-add.svg") no-repeat center / contain;
                display: inline-block;
                position: absolute;
                width: 15px;
                height: 15px;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
    }

    .pro-img {
        width: 100%;
        display: inline-block;
        text-align: center;

        img {
            display: inline-block;
            width: auto;
            object-fit: contain;
            height: 200px;
        }
    }

    .pro-card-header {
        width: 100%;
        display: inline-block;
        margin-top: 20px;

        .pro-mood {
            display: flex;
            align-items: center;
            margin: 0 0 10px;
            min-height: 26.48px;

            img {
                margin: 0 10px 0 0;
                width: 23px;
                height: auto;
            }

            p {
                font-size: 12px;
                color: #000;
            }
        }

        .pro-title {
            width: 100%;
            display: inline-block;
            margin: 0 0 5px 0;

            h5 {
                font-size: 16px;
                color: #070605;
                line-height: normal;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: normal;
                font-weight: 600;
                min-height: 38px;
                text-align: left;
                margin: 0;
            }
        }

        .pro-price {
            width: 100%;
            display: inline-block;
            margin: 5px 0 0;
            text-align: left;

            .pro-price-amount {
                color: $black-color;
                font-weight: 600;
                font-size: 16px;

                .price-currency-symbol {
                    margin-right: 1px;
                }
            }

            span.pro-price-amount-del {
                color: #707070;
                text-decoration: line-through;
                margin: 0 10px 0 0;
                font-size: 14px;
                font-weight: 500;
            }
        }

        .pro-add-to-cart-qty {
            width: 100%;
            display: -webkit-box;
            /* OLD - iOS 6-, Safari 3.1-6 */
            display: -moz-box;
            /* OLD - Firefox 19- (buggy but mostly works) */
            display: -ms-flexbox;
            /* TWEENER - IE 10 */
            display: -webkit-flex;
            /* NEW - Chrome */
            display: flex;
            /* NEW, Spec - Opera 12.1, Firefox 20+ */
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;

            .pro-quantity {
                border: 1px solid $black-color;
                display: -webkit-box;
                /* OLD - iOS 6-, Safari 3.1-6 */
                display: -moz-box;
                /* OLD - Firefox 19- (buggy but mostly works) */
                display: -ms-flexbox;
                /* TWEENER - IE 10 */
                display: -webkit-flex;
                /* NEW - Chrome */
                display: flex;
                /* NEW, Spec - Opera 12.1, Firefox 20+ */
                align-items: center;
                flex-direction: row-reverse;
                background-color: $white-color;
                border-radius: 5px;
                width: 80px;
                margin-right: 5px;
                height: 38px;

                .cus-qty {
                    width: 100%;
                    // display: inline-block;
                    padding: 0 3px;

                    &::before {
                        border: none;
                    }

                    >div {
                        padding: 0;
                        width: 100%;
                    }

                    button {
                        padding: 0;
                        color: $dark-black;

                        svg {
                            width: 18px;
                            height: 18px;
                        }
                    }

                    input {
                        width: 100% !important;
                        text-align: center;
                        font-size: 16px;
                        font-weight: bold;
                        width: auto;
                        padding: 0;
                        height: 40px;
                        color: $dark-black;
                    }

                    fieldset {
                        display: none;
                    }
                }
            }

            .add-to-cart-button {
                background-color: $black-color;
                color: $white-color;
                font-weight: bold;
                font-size: 14px;
                text-transform: uppercase;
                border-radius: 5px;
                padding: 0;
                height: 44px;
                display: -moz-box;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                -moz-box-align: center;
                align-items: center;
                -webkit-justify-content: center;
                -moz-box-pack: center;
                justify-content: center;
                width: 100%;
                line-height: 44px;

                &:hover {
                    background-color: $light-green;
                    color: $white-color;
                }
            }
        }
    }
}

.custom-menu-p-tag {
    display: flex;

    button {
        padding: 0px;

        span {
            font-size: 14px;
        }
    }
}

.cus-table-dropdown {
    .MuiIconButton-label {
        color: $light-blue;
    }
}

.no-data-found {
    p {
        margin: 0px;
    }
}

.material-ui-icons {
    cursor: pointer;
    font-size: 20px !important;
}

// Table design
.cus-table {
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    border-radius: 0px;

    .box-shadow-none {
        box-shadow: none;
    }

    .MuiTableCell-head {
        white-space: nowrap;
        text-transform: capitalize;
        font-weight: bold;
    }
}

a.link-tag.btn {
    background-color: $black-color;
    display: inline-block;
    color: $white-color;
    padding: 0px 20px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    height: 36px;
    line-height: 36px;

    &:hover {
        background-color: $dark-green;
    }
}

span.text-top {
    display: block;
    text-align: left;
    text-transform: capitalize;
    color: $dark-green;
}

body {
    .MuiPaper-elevation1 {
        -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        border-radius: 0;
    }

    .MuiInputBase-root {
        fieldset {
            border-radius: 0;
        }
    }
}

// Add categories popup
.cus-modal {
    .add-button {
        margin-top: 16px;
        margin-bottom: 16px;
    }

}

// Terminal
.cust-google-field {
    width: 100% !important;
}

// Code for Add Shadow in buttons
.tabs-section-div {

    // margin: 0px auto 10px ;
    // width: 400px;
    // display: flex;
    // -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    // -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    // box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    div {
        // width: auto;
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.selected-tab {
            cursor: default !important;
            background: $black-color;
            color: white;
        }

        span {
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}

.manage-top-tabs {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.cus-threshold {
    text-align: right;
}


.setting-top {
    width: 50% !important;

    .threshold-box {
        display: flex;
        align-items: center;
        // padding: 15px;
        min-height: 75px;
    }

    .btn-align {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            padding: 0 12px;
        }
    }
}

.cur-pointer {

    &.edit-icon,
    &.done-icon {
        background: green;
        width: 24px;
        height: 24px;
        display: inline-block;
        border-radius: 4px;
        text-align: center;

        svg {
            width: 16px;
            color: #fff;
        }
    }

    &.done-icon {
        background: #46952f;
    }

    &.delete-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        background: #e01000;
        border-radius: 4px;
        margin-left: 5px;
        text-align: center;

        svg {
            width: 16px;
            color: #fff;
        }
    }
}

.MuiSwitch-colorSecondary {
    &.Mui-checked {
        color: #46952f !important;

        +.MuiSwitch-track {
            background-color: #46952f !important;
        }
    }
}

textarea {
    border: 1px solid rgba(0, 0, 0, 0.23);
    resize: none;
    padding: 12px;
    border-radius: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #000000de;
}

.bg-border {
    border: 1px solid rgba(0, 0, 0, 0.38);
    margin: 10px 0 0px;
    padding: 10px 12px 4px;
    background: #46952f1c;

    p {
        font-weight: 700;
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
        color: #46952f;
    }
}